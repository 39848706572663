import React, { useEffect, useState } from "react";
import { RouteProps } from "react-router";
import { FirmwareDetails } from "../component/FirmwareDetails";
import { FormattedDate } from "../component/FormattedDate";
import { PlatformDisplay } from "../component/PlatformDisplay";
import { PlatformSelector } from "../component/PlatformSelector";
import { PageResponse } from "../data/data";
import { Firmware, FirmwareChannelResponse, HardwarePlatform, dataFirmware } from "../data/dataFirmware";
import "./LoginPage.scss";
import { Page } from "./Page";
import { Link } from "react-router-dom";
import { UPLOAD_PAGE_ROUTE } from "./FirmwareUploadPage";
import { LuFileWarning } from "react-icons/lu";

export const DASHBOARD_ROUTE = "/dashboard";

export const DashboardPage: React.FunctionComponent<RouteProps> = () => {
  const [availablePlatforms, setAvailablePlatforms] = useState<HardwarePlatform[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<string | undefined>(undefined);
  const [firmwares, setFirmwares] = useState<PageResponse<Firmware> | undefined>();
  const [firmwareDetails, setFirmwareDetails] = useState<Firmware | undefined>(undefined);
  const [releaseChannels, setReleaseChannels] = useState<FirmwareChannelResponse[]>([]);

  useEffect(() => {
    dataFirmware.getPlatforms().then(setAvailablePlatforms);
  }, []);

  useEffect(() => {
    if (availablePlatforms.length > 0) {
      setSelectedPlatform(availablePlatforms[0].platform);
    }
  }, [availablePlatforms]);

  useEffect(() => {
    if (!selectedPlatform || availablePlatforms.length === 0) {
      return;
    }
    const platforms = availablePlatforms.filter((platform) => platform.platform === selectedPlatform);
    if (platforms.length === 0) {
      return;
    }
    const platformIds = platforms.map((platform) => platform.platformId);
    dataFirmware.getFirmware(platformIds, 0, 10).then(setFirmwares);
    dataFirmware.getPlatformChannels(platformIds).then(setReleaseChannels);
  }, [selectedPlatform, availablePlatforms]);

  return (
    <Page>
      <div className="card">
        <div className="card-body">
          <header>
            <h2>Firmware overview {selectedPlatform}</h2>
          </header>
          {firmwares && firmwareDetails ? (
            <FirmwareDetails
              selectedFirmware={firmwareDetails}
              platforms={availablePlatforms}
              availableFirmwares={firmwares.content}
              onCancel={() => setFirmwareDetails(undefined)}
            />
          ) : (
            <React.Fragment>
              <PlatformSelector platforms={availablePlatforms} selected={selectedPlatform} onChange={setSelectedPlatform} />
              <div className="w-100 text-end mt-2">
                {selectedPlatform && (
                  <Link to={UPLOAD_PAGE_ROUTE + "?platform=" + encodeURI(selectedPlatform)} className="btn btn-primary">
                    Upload new firmware
                  </Link>
                )}
              </div>

              <div className="table-responsive">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Channel</th>
                      <th>Version</th>
                      <th>Release date</th>
                      <th className="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {releaseChannels.map((channel) => (
                      <tr key={channel.firmwareChannel.id}>
                        <td>{channel.firmwareChannel.name}</td>
                        <td>{channel.firmware ? channel.firmware.version : "-"}</td>
                        <td>{channel.firmware ? <FormattedDate date={channel.firmware.created} /> : "-"}</td>
                        <td className="text-end">
                          <button
                            className="btn btn-secondary btn-sm"
                            disabled={!channel.firmware}
                            onClick={() => setFirmwareDetails(channel.firmware)}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="table-responsive mt-2">
                <table className="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Version</th>
                      <th>Platform</th>
                      <th>Release date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {firmwares?.content.map((firmware) => (
                      <tr key={firmware.id}>
                        <td>{firmware.version}</td>
                        <td>
                          <PlatformDisplay platforms={availablePlatforms} selectedId={firmware.platformId} />
                        </td>
                        <td>
                          <FormattedDate date={firmware.created} />
                        </td>
                        <td className="text-end">
                          <div className="btn btn-secondary btn-sm" onClick={() => setFirmwareDetails(firmware)}>
                            Details {firmware.hasData ? "" : <LuFileWarning />}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </Page>
  );
};
