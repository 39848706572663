import React from "react";
import { FunctionComponent } from "react";
import { HardwarePlatform } from "../data/dataFirmware";

interface Props {
  platforms: HardwarePlatform[];
  selected: string | undefined;
  onChange: (platform: string) => void;
}

export const PlatformSelector: FunctionComponent<Props> = ({ platforms, selected, onChange }) => {
  const uniquePlatforms = Array.from(new Set(platforms.map((platform) => platform.platform))).map((platform) =>
    platforms.find((p) => p.platform === platform)
  );
  return (
    <React.Fragment>
      <select className="form-select bg-white" value={selected ? selected : ""} onChange={(handleSelect) => onChange(handleSelect.target.value)}>
        <option value="">Select an item</option>
        {uniquePlatforms.map((platform) =>
          platform ? (
            <option key={platform.platform} value={platform.platform}>
              {platform.platform}
            </option>
          ) : null
        )}
      </select>
    </React.Fragment>
  );
};
