interface Props {
  /**
   * Size of the spinner.
   * @default medium - 72px
   */
  size?: "small" | "medium" | "large";
  /**
   * Display "Loading" below the spinner.
   * @default false
   */
  withText?: boolean;
  className?: string;
  color?: string;
}
export const Spinner: React.FunctionComponent<Props> = ({ size = "medium", withText = false, color = "#55C4A4", className }) => {
  return <div className="spinner">spinner</div>;
};
