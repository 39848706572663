import React from "react";
import { FunctionComponent } from "react";
import { HardwarePlatform } from "../data/dataFirmware";

interface Props {
  platforms: HardwarePlatform[];
  selectedId: number | undefined;
}

export const PlatformDisplay: FunctionComponent<Props> = ({ platforms, selectedId }) => {
  const selectedPlatform = platforms.filter((platform) => platform.platformId === selectedId);

  return (
    <React.Fragment>
      {selectedPlatform && selectedPlatform.length > 0 ? selectedPlatform[0].internalName : "No Platform Selected"}
    </React.Fragment>
  );
};
