import { nanoid } from "nanoid";
import { Dynamic, DynamicOfType } from "./data/types";

export const MISSING_DATE = " - ";

export function id() {
  return nanoid();
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
export function returnFileSize(size: number) {
  if (size < 1024) {
    return size + "bytes";
  } else if (size >= 1024 && size < 1048576) {
    return (size / 1024).toFixed(1) + "KB";
  } else if (size >= 1048576) {
    return (size / 1048576).toFixed(1) + "MB";
  }
}

export function trimAllButAlphaNumeric(value?: string) {
  if (!value) {
    return "";
  }

  return value.replaceAll(/[\W_]+/g, "");
}

export function getDynamicLink(dynamicUrl: string, dynamicValues: Dynamic) {
  let url = dynamicUrl;
  Object.keys(dynamicValues).forEach((key) => {
    const regEx = new RegExp(`:${key}(\\?)?`, "gi");
    url = url.replace(regEx, dynamicValues[key]);
  });
  return url;
}

interface CasType {
  cas?: number;
  [key: string]: any;
}

export function clearCas(data: CasType | CasType[]) {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const copy = { ...item };
      delete copy.cas;
      return copy;
    });
  }

  const copy = { ...data };
  delete copy.cas;
  return copy;
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.getTime());
}

export function getLatestValueByKey<T>(key: keyof T, values: T[]) {
  return sortLatestByKey(key, values)[0];
}

export function sortLatestByKey<T>(key: keyof T, values: T[]) {
  // don't modify original array
  const copy = [...values];
  copy.sort((a: T, b: T) => {
    const aValue = a[key];

    if (typeof aValue === "string") {
      return new Date(b[key] as unknown as string).getTime() - new Date(aValue).getTime();
    }

    if (typeof (aValue as unknown as Date).getMonth === "function") {
      return (b[key] as unknown as Date).getTime() - (aValue as unknown as Date).getTime();
    }

    return 0;
  });

  return copy;
}

export function convertArrayToObjectByKey<T>(array: T[], key: string) {
  const item: DynamicOfType<T> = {};
  return array.reduce((acc, curr) => {
    acc[(curr as any)[key] as string] = curr;
    return acc;
  }, item);
}

export function convertDynamicObjectToArray<T>(obj: any): T[] {
  return Object.keys(obj).map((key) => obj[key]);
}

export const getCurrencySymbol = (locale: string, currency: string) =>
  (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
