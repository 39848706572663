import { SessionResponse } from "./data/dataAuth";

export interface Filter {
  page: number;
  size?: number;
  adminId?: string;
  sortField?: string;
}

export const defaultFilter: Filter = {
  page: 0,
};

export enum Theme {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum STORE_KEY {
  STORE_THEME = "store.ui.theme",
  STORE_LOGIN_EMAIL = "store.auth.login.email",
  STORE_USER = "store.auth.user",
  STORE_FEATURES = "store.features",
}

interface StoredData {
  [STORE_KEY.STORE_THEME]: Theme;
  [STORE_KEY.STORE_LOGIN_EMAIL]: string;
  [STORE_KEY.STORE_USER]: SessionResponse | null;
}

const NAME = "ng-store";

const defaultValues = {
  [STORE_KEY.STORE_THEME]: Theme.LIGHT,
  [STORE_KEY.STORE_LOGIN_EMAIL]: "",
  [STORE_KEY.STORE_USER]: null,
};

let storedData: StoredData = { ...defaultValues };
const storedStringData = window.localStorage.getItem(NAME);
if (storedStringData) {
  try {
    storedData = { ...defaultValues, ...JSON.parse(storedStringData) };
  } catch (err) {}
}

export const Store = {
  getValue<T>(key: keyof StoredData) {
    return storedData[key] as unknown as T;
  },

  setValue<Key extends keyof StoredData>(key: Key, value: StoredData[Key]) {
    storedData[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(storedData));
    } catch (err) {
      //Do not log
    }
  },

  getStorage() {
    return storedData;
  },
};
