import cx from "classnames";
import React, { useEffect } from "react";
import "./Page.scss";
import { FiLogOut } from "react-icons/fi";
import { LOGOUT_ROUTE } from "./Logout";
import { Link } from "react-router-dom";

export interface PageProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  stripped?: boolean;
}

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  className,
  title,
  stripped = false,
}) => {
  useEffect(() => {
    if (!title) {
      document.title = "NordicGamekeeper";
      return;
    }
    document.title = `${title} | NordicGamekeeper`;
  }, [title]);

  if (stripped) {
    return (
      <section>
        <article>
          <div className={cx("page", className)}>
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                  <header className="mt-4 mb-4 w-100 justify-content-center align-items-center">
                    <img
                      className="w-100"
                      src="ng_logo_flat_white_slim.png"
                      alt="ng-logo"
                    />
                  </header>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    );
  }

  return (
    <section>
      <article>
        <div className={cx("page", className)}>
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-8">
                <header className="mt-4 mb-4 w-100 d-flex justify-content-between text-white">
                  <div>
                    <img
                      src="ng_logo_flat_white_slim.png"
                      alt="ng-logo"
                      className="logo"
                    />
                  </div>
                  <div>
                    <Link to={LOGOUT_ROUTE} className="logout">
                      <FiLogOut size={36} className="text-white mt-2" title="Logout"/>
                    </Link>
                  </div>
                </header>
                {children}
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};
