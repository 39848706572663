import { PageResponse, data } from "./data";

export interface HardwarePlatform {
  internalName: string;
  platformId: number;
  platform: string;
}

export interface Firmware {
  version: string;
  description: string;
  id: string;
  title: string;
  artifactUrl: string;
  platformId: number;
  created: string;
  hasData: boolean;
}

export interface FirmwareChannel {
  id: string;
  name: string;
}

export interface FirmwareChannelResponse {
  firmwareChannel: FirmwareChannel;
  firmware?: Firmware;
}

export interface FirmwareDeployResponse {
  success: boolean;
  received: string;
  deployed?: string;
  version?: string;
  file1Length?: number;
  file2Length?: number;
}

export const dataFirmware = {
  getPlatforms: () => data.get<HardwarePlatform[]>("/api/firmware/platforms", false),

  getPlatformChannels: (platformIds: number[]) => {
    const url = new URL(`/api/firmware/channels`, window.location.origin);
    platformIds.forEach((platformsId) => {
      url.searchParams.append("platform", platformsId.toString());
    });
    return data.get<FirmwareChannelResponse[]>(url.toString(), false);
  },

  getFirmware: (platformIds: number[], page: number, size: number) => {
    const url = new URL(`/api/firmware/list`, window.location.origin);
    platformIds.forEach((platformsId) => {
      url.searchParams.append("platform", platformsId.toString());
    });
    url.searchParams.append("page", page.toString());
    url.searchParams.append("size", size.toString());
    return data.get<PageResponse<Firmware>>(url.toString(), false);
  },

  getFirmwareUrl: (firmwareId: string) => {
    const url = new URL(`/api/firmware/{id}/data`, window.location.origin);
    url.searchParams.append("id", firmwareId);
    return url.toString();
  },

  postFirmwareDeploy: (formData: FormData) => {
    return data.postForm<FirmwareDeployResponse>("/api/firmware", formData);
  },
};
