import React, { useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { STORE_KEY, Store } from "../Store";
import { userState } from "../state/userState";
import "./Logout.scss";
import { Page } from "./Page";

export const LOGOUT_ROUTE = "/logout";

export const Logout: React.FunctionComponent = () => {
  const setUserState = useSetRecoilState(userState);
  const { search } = useLocation();
  const params = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const expired = params.has("expired");

  useEffect(() => {
    Store.setValue(STORE_KEY.STORE_USER, null);
    setUserState(null);
  }, [setUserState]);

  return (
    <Page stripped>
      <div className="card">
        <div className="card-body">
          {expired ? (
            <>
              <h4>Session expired</h4>
              <p>So, it seems like your session has expired. You will have to sign in again. No worries. Take care.</p>
            </>
          ) : (
            <>
              <h4>Logged out</h4>
              <p>You are now logged out</p>
            </>
          )}
          <div className="d-grid gap-2">
            <Link to="/" className="btn btn-primary btn-block">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
};
