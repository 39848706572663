import classNames from "classnames";
import React, { useCallback, useState, ChangeEvent } from "react";
import { Status } from "../data/types";
import { id as generateId } from "../utils";

export interface Props {
  id?: string;
  onChange: (value: string, name: string, ev?: ChangeEvent<HTMLTextAreaElement>) => void;
  textValidator: (value: string) => string | undefined;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const [text, setText] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const id = props.id ? props.id : generateId();

  const onBlur = useCallback(() => {
    const error = props.textValidator ? props.textValidator(text.trim()) : undefined;
    if (error) {
      setStatus(Status.ERROR);
      setErrorText(error);
    } else {
      setStatus(Status.DEFAULT);
      setErrorText("");
    }
  }, [text, props]);

  return (
    <React.Fragment>
      <textarea
        ref={ref}
        value={text}
        id={id}
        className={classNames("form-control bg-white", {
          "is-valid": status === Status.SUCCESS,
          "is-invalid": status === Status.ERROR,
        })}
        onBlur={onBlur}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          setText(e.target.value);
          props.onChange(e.target.value, id, e);
        }}
      />
      <div className="invalid-feedback">{errorText}</div>
    </React.Fragment>
  );
});
