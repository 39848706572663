import { data } from "./data";

interface LoginResponse {
  requestId: string;
}

export interface SessionResponse {
  userId: string;
  expiry: string; // datetime
  email: string;
}

export const dataAuth = {
  startLogin: (email: string) =>
    data.post<LoginResponse>("/api/login", {
      email,
    }),

  postOtp: (requestId: string, otp: string) =>
    data.post<SessionResponse>("/api/login/otp", {
      code: otp,
      requestId,
    }),
  getCurrentSession: () => data.get<SessionResponse>("/api/whoami", true),
};
