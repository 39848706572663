import { DateTime } from "luxon";
import React, { Suspense } from "react";
import { BrowserRouter, Route, RouteProps, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Spinner } from "./component/Spinner";
import { DASHBOARD_ROUTE, DashboardPage } from "./pages/DashboardPage";
import { LoginPage } from "./pages/LoginPage";
import { userState } from "./state/userState";
import { LOGOUT_ROUTE, Logout } from "./pages/Logout";
import { FirmwareUploadPage, UPLOAD_PAGE_ROUTE } from "./pages/FirmwareUploadPage";

interface Props extends RouteProps {}

const SecureRoute: React.FunctionComponent<Props> = ({ exact, path, component, children }) => {
  const user = useRecoilValue(userState);

  if (!user) {
    return <Route component={LoginPage} />;
  }

  const isPast = DateTime.now() > DateTime.fromISO(user.expiry);
  if (isPast) {
    return <Route component={LoginPage} />;
  }

  return <Route {...{ component, children, exact, path }} />;
};

function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="loading">
            <Spinner size="large" />
          </div>
        }
      >
        <main>
          <Switch>
            <SecureRoute exact path={DASHBOARD_ROUTE} component={DashboardPage} />
            <SecureRoute exact path={UPLOAD_PAGE_ROUTE} component={FirmwareUploadPage} />
            <Route exact path={LOGOUT_ROUTE} component={Logout} />
            <Route component={LoginPage} />
          </Switch>
        </main>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
