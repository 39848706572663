import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// import { Button } from "../../components/interactions/Buttons/Button";
// import { Input } from "../../components/interactions/Input/Input";
import { dataAuth, SessionResponse } from "../data/dataAuth";
import { Status } from "../data/types";
import { useIsMountedRef } from "../hooks/useIsMounted";
import classnames from "classnames";
// import { Status } from "../../data/types";
// import { useIsMountedRef } from "../../hooks/useIsMounted";

interface Props {
  onLogin: (sessionResponse: SessionResponse) => void;
  requestId?: string;
  onReset: () => void;
}

export const LoginOtp: React.FunctionComponent<Props> = ({
  onLogin,
  requestId,
  onReset,
}) => {
  const [otp, setOtp] = useState<string | undefined>(undefined);
  const [otpStatus, setOtpStatus] = useState<Status>(Status.DEFAULT);
  const [otpError, setOtpError] = useState<ReactNode>();
  const statusRef = useRef<Status>(Status.DEFAULT);
  const mounted = useIsMountedRef();
  const formRef = useRef<HTMLFormElement>(null);

  const updateStatus = useCallback((statusValue: Status) => {
    statusRef.current = statusValue;
    setOtpStatus(statusValue);
  }, []);

  const onOtpSubmit = useCallback(
    (ev: { preventDefault: () => void }) => {
      ev.preventDefault();
      updateStatus(Status.PENDING);

      const error = otp ? "" : "A code has to be supplied";
      if (error) {
        updateStatus(Status.ERROR);
        setOtpError(error);
        return;
      }

      dataAuth
        .postOtp(requestId || "", otp || "")
        .then(onLogin)
        .catch(() => {
          updateStatus(Status.ERROR);
          setOtpError("Ooh no! Something bad happened. Try again?");

          setTimeout(() => {
            if (!mounted.current) {
              return;
            }

            if (statusRef.current !== Status.ERROR) {
              return;
            }

            updateStatus(Status.DEFAULT);
          }, 4000);
        });
    },
    [requestId, otp, onLogin, updateStatus, mounted]
  );

  const onBlur = useCallback(() => {
    const error = otp ? "" : "A code has to be supplied";
    if (error) {
      updateStatus(Status.ERROR);
      setOtpError(error);
    }
  }, [otp, updateStatus]);

  const onOtpChange = useCallback(
    (val: React.SetStateAction<string | undefined>) => {
      updateStatus(Status.DEFAULT);
      setOtp(val);
    },
    [updateStatus]
  );

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    const elem = formRef.current.querySelector("[name='input-login-otp']");
    if (elem) {
      setTimeout(() => {
        (elem as HTMLInputElement).focus();
      }, 900);
    }
  }, []);

  if (!requestId) {
    onReset();
  }

  return (
    <form onSubmit={onOtpSubmit} ref={formRef}>
      <p>
        <b>Perfect!</b> A login code was sent to your email address.
      </p>
      <div className="mb-3">
        <label htmlFor="emailInput" className="form-label">
          Enter code:
        </label>
        <input
          type="otp"
          className={classnames("form-control bg-white", {
            "is-valid": otpStatus === Status.SUCCESS,
            "is-invalid": otpStatus === Status.ERROR,
          })}
          id="otpInput"
          placeholder="Please check your mail for the login code"
          value={otp}
          onBlur={onBlur}
          onChange={(val) => onOtpChange(val.target.value)}
        />
        {otpError && <small className="text-danger">{otpError}</small>}
      </div>
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-primary btn-block">
          Verify
        </button>
        <button
          type="submit"
          className="btn btn-secondary btn-block"
          onClick={onReset}
        >
          Reset
        </button>
      </div>
    </form>
  );
};
