import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./index.scss";
import { RecoilRoot } from 'recoil';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// curl -X POST -H "Content-Type: application/json" -d '{"email":"ola@nordicgamekeeper.com"}' http://localhost:8081/api/login
// curl -X POST -H "Content-Type: application/json" -d '{"requestId":"3934cdb1-9dc0-43a6-b602-80148b6509c6", "code":"63878247"}' http://localhost:8081/api/login/otp