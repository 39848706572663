import React, { useEffect, useState } from "react";
import { RouteProps } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { Input } from "../component/Input";
import { TextArea } from "../component/Textarea";
import { FirmwareDeployResponse, HardwarePlatform, dataFirmware } from "../data/dataFirmware";
import { ServerError } from "../network/API";
import { DASHBOARD_ROUTE } from "./DashboardPage";
import "./LoginPage.scss";
import { Page } from "./Page";
import { FormattedDate } from "../component/FormattedDate";

export const UPLOAD_PAGE_ROUTE = "/upload";

export const FirmwareUploadPage: React.FunctionComponent<RouteProps> = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [availablePlatforms, setAvailablePlatforms] = useState<HardwarePlatform[]>([]);

  const [uploadError, setUploadError] = useState<string>("");
  const [version, setVersion] = useState<string>("");
  const [trackingId, setTrackingId] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [sha1, setSha1] = useState<string>("");
  const [file1, setFile1] = useState<File | undefined>(undefined);
  const [sha2, setSha2] = useState<string>("");
  const [file2, setFile2] = useState<File | undefined>(undefined);

  const [uploading, setUploading] = useState<boolean>(false);

  const [deployedFirmware, setDeployedFirmare] = useState<FirmwareDeployResponse>();
  const platform = searchParams.get("platform");

  const platform1 = availablePlatforms.length > 0 ? availablePlatforms[0] : undefined;
  const platform2 = availablePlatforms.length > 1 ? availablePlatforms[1] : undefined;

  useEffect(() => {
    dataFirmware.getPlatforms().then((platforms) => setAvailablePlatforms(platforms.filter((p) => p.platform === platform)));
  }, [platform]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setUploading(true);
    const formData = new FormData();
    formData.append("version", version);
    formData.append("trackingId", trackingId);
    formData.append("description", description);
    formData.append("sha1", sha1);
    formData.append("file1", file1 as Blob);
    formData.append("platform1", platform1?.platformId.toString() || "");
    if (file2) {
      formData.append("sha2", sha2);
      formData.append("file2", file2 as Blob);
      formData.append("platform2", platform2?.platformId.toString() || "");
    }
    dataFirmware
      .postFirmwareDeploy(formData)
      .then((resp) => {
        setUploadError("");
        setDeployedFirmare(resp);
      })
      .catch((error) => {
        if (error instanceof ServerError) {
          setUploadError("Upload error, please check input parameters and test again. (" + error.statusText + ")");
        } else {
          setUploadError("Upload error, please check input parameters and test again.");
        }
      })
      .finally(() => setUploading(false));
  };

  if (deployedFirmware) {
    return (
      <Page>
        <div className="card">
          <div className="card-body">
            <header>
              <h2>Deployed {deployedFirmware.version}</h2>
            </header>
            <dl>
              <dt>Version</dt>
              <dd>{deployedFirmware.version}</dd>
              <dt>Received</dt>
              <dd>
                <FormattedDate date={deployedFirmware.received} pattern="yyyy-MM-dd HH:mm:ss" />
              </dd>
              <dt>Deployed</dt>
              <dd>
                <FormattedDate date={deployedFirmware.deployed} pattern="yyyy-MM-dd HH:mm:ss" />
              </dd>
              <dt>File size</dt>
              <dd>{deployedFirmware.file1Length}&nbsp;bytes</dd>
              {deployedFirmware.file2Length && (
                <React.Fragment>
                  <dt>File 2 size</dt>
                  <dd>{deployedFirmware.file2Length}&nbsp;bytes</dd>
                </React.Fragment>
              )}
            </dl>
            <div className="text-end">
              <Link className="btn btn-primary" to={DASHBOARD_ROUTE}>
                Done
              </Link>
            </div>
          </div>
        </div>
      </Page>
    );
  }

  if (availablePlatforms.length === 0 || platform1 === undefined) {
    return (
      <Page>
        <div className="card">
          <div className="card-body">
            <header>
              <h2>Loading...</h2>
            </header>
            <div className="text-end">
              <Link className="btn btn-primary" to={DASHBOARD_ROUTE}>
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Page>
      <div className="card">
        <div className="card-body">
          <header>
            <h2>{availablePlatforms[0].platform} firmware upload</h2>
          </header>

          <div className="mt-2">
            <label htmlFor="version" className="fw-bold">
              Version
            </label>
            <Input onChange={setVersion} textValidator={(value) => (value.length === 0 ? "Version is required" : undefined)} />
          </div>
          <div className="mt-2">
            <label htmlFor="githash" className="fw-bold">
              Githash / Tracking id
            </label>
            <Input
              onChange={setTrackingId}
              textValidator={(value) => (value.length === 0 ? "GitHash / TrackingId is required" : undefined)}
            />
          </div>
          <div className="mt-2 mb-3">
            <label htmlFor="description" className="fw-bold">
              Description
            </label>
            <TextArea onChange={setDescription} textValidator={(value) => (value.length === 0 ? "Description is required" : undefined)} />
          </div>

          <div className="mt-3">
            <label htmlFor={"sha" + platform1.platformId} className="fw-bold">
              {platform1.internalName} sha256
            </label>
            <Input onChange={setSha1} textValidator={(value) => (value.length === 0 ? "SHA256 of file is required" : undefined)} />
          </div>
          <div className="custom-file mt-2">
            <label className="fw-bold" htmlFor={"file" + platform1.platformId}>
              {platform1.internalName} File
            </label>
            <input
              id={"id" + platform1.platformId}
              className="form-control bg-white"
              type="file"
              onChange={(e) => setFile1(e.target.files?.[0])}
            />
          </div>

          {platform2 && (
            <React.Fragment>
              <div className="mt-3">
                <label htmlFor={"sha" + platform2.platformId} className="fw-bold">
                  {platform2.internalName} sha256
                </label>
                <Input onChange={setSha2} textValidator={(value) => (value.length === 0 ? "SHA256 of file is required" : undefined)} />
              </div>
              <div className="custom-file mt-2">
                <label className="fw-bold" htmlFor={"file" + platform2.platformId}>
                  {platform2.internalName} File
                </label>
                <input
                  id={"id" + platform2.platformId}
                  className="form-control bg-white"
                  type="file"
                  onChange={(e) => setFile2(e.target.files?.[0])}
                />
              </div>
            </React.Fragment>
          )}

          <div className="text-end mt-3">
            <button
              className="btn btn-primary me-4"
              disabled={!version || !trackingId || !description || !sha1 || !file1 || !sha2 || !file2 || uploading}
              onClick={handleSubmit}
            >
              Upload
            </button>
            <Link className="btn btn-primary" to={DASHBOARD_ROUTE}>
              Cancel
            </Link>
          </div>
          <div className="text-danger">
            <small>{uploadError}</small>
          </div>
        </div>
      </div>
    </Page>
  );
};
