import classnames from "classnames";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { STORE_KEY, Store } from "../Store";
import { dataAuth } from "../data/dataAuth";
import { Status } from "../data/types";
import { useIsMountedRef } from "../hooks/useIsMounted";

interface Props {
  onNext: (code: string) => void;
}

function isValidEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function getPotentialError(email: string) {
  if (isValidEmail(email)) {
    return undefined;
  }

  if (!email) {
    return "Email has to be supplied";
  }

  return "Seems like this is an invalid email address?";
}

export const LoginEmail: React.FunctionComponent<Props> = ({ onNext }) => {
  const [email, setEmail] = useState<string>(Store.getValue<string>(STORE_KEY.STORE_LOGIN_EMAIL));
  const [emailError, setEmailError] = useState<ReactNode>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [loading, setLoading] = useState<boolean>(false);
  const requestId = useRef<string>();
  const statusRef = useRef<Status>(Status.DEFAULT);
  const mounted = useIsMountedRef();
  const formRef = useRef<HTMLFormElement>(null);

  const updateStatus = useCallback((statusValue: Status) => {
    statusRef.current = statusValue;
    setStatus(statusValue);
  }, []);

  const onEmailChange = useCallback(
    (val: string) => {
      updateStatus(Status.DEFAULT);
      setEmailError("");
      setEmail(val.trim());
    },
    [updateStatus]
  );

  const onBlur = useCallback(() => {
    const error = getPotentialError(email);
    if (error) {
      updateStatus(Status.ERROR);
      setEmailError(error);
    } else {
      updateStatus(Status.DEFAULT);
      setEmailError("");
    }
  }, [email, updateStatus]);

  const onEmailSubmit = useCallback(
    (ev: { preventDefault: () => void }) => {
      ev.preventDefault();
      updateStatus(Status.PENDING);

      const error = getPotentialError(email);
      if (error) {
        updateStatus(Status.ERROR);
        setEmailError(error);
        return;
      }

      setEmailError("");
      setLoading(true);
      dataAuth
        .startLogin(email)
        .then((data) => {
          Store.setValue(STORE_KEY.STORE_LOGIN_EMAIL, email);
          requestId.current = data.requestId;
          onNext(requestId.current);
          updateStatus(Status.DEFAULT);
        })
        .catch((err) => {
          setEmailError("Ooh no! Something bad happened. Try again?");
          updateStatus(Status.ERROR);
          requestId.current = undefined;

          setTimeout(() => {
            if (!mounted.current) {
              return;
            }

            if (statusRef.current !== Status.ERROR) {
              return;
            }

            updateStatus(Status.DEFAULT);
          }, 4000);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [email, onNext, updateStatus, mounted]
  );

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    const elem = formRef.current.querySelector("[name='input-login-email']");
    if (elem) {
      setTimeout(() => {
        (elem as HTMLInputElement).focus();
      }, 100);
    }
  }, []);

  return (
    <form onSubmit={onEmailSubmit} ref={formRef}>
      <div className="mb-3">
        <label htmlFor="emailInput" className="form-label">
          Email address:
        </label>
        <input
          type="email"
          className={classnames("form-control bg-white", {
            "is-valid": status === Status.SUCCESS,
            "is-invalid": status === Status.ERROR,
          })}
          id="emailInput"
          placeholder="Enter email"
          value={email}
          onBlur={onBlur}
          onChange={(val) => onEmailChange(val.target.value)}
        />
        <div className="invalid-feedback">{emailError}</div>
      </div>
      <div className="d-grid gap-2">
        <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
          {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
          Sign In
        </button>
      </div>
    </form>
  );
};
