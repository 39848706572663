import React, { FunctionComponent } from "react";
import { Firmware, HardwarePlatform, dataFirmware } from "../data/dataFirmware";

interface Props {
  availableFirmwares: Firmware[];
  platforms: HardwarePlatform[];
  selectedFirmware: Firmware | undefined;
  onCancel: () => void;
}

export const FirmwareDetails: FunctionComponent<Props> = ({ availableFirmwares, platforms, selectedFirmware, onCancel }) => {
  if (!selectedFirmware) {
    return null;
  }



  // get all available firmware with same version for feedcon
  const firmwares = availableFirmwares.filter((firmware) => firmware.version === selectedFirmware.version);

  return (
    <React.Fragment>
      <dl>
        <dt>Version</dt>
        <dd>{selectedFirmware.version}</dd>
        <dt>Githash (formerly title)</dt>
        <dd>{selectedFirmware.title}</dd>
        <dt>Released</dt>
        <dd>{selectedFirmware.created}</dd>
        <dt>Description</dt>
        <dd>{selectedFirmware.description}</dd>
        {firmwares.map((firmware) => (
          <React.Fragment>
            <dt>SHA256 {platforms && platforms.find(p => p.platformId === firmware.platformId)?.internalName}</dt>
            <dd>{selectedFirmware.id}</dd>
          </React.Fragment>
        ))}
        <dt>Actions</dt>
        <dd>
          <div className="d-flex justify-content-between mt-2">
            <button className="btn btn-secondary" onClick={() => onCancel()}>
              Replace
            </button>
            {firmwares.map((firmware) => (
              <button key={firmware.id} className="btn btn-secondary" disabled={!firmware.hasData}>
                <a
                  key={firmware.id}
                  className="btn btn-secondary"
                  href={dataFirmware.getFirmwareUrl(firmware.id)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download {platforms.find((platform) => platform.platformId === firmware.platformId)?.internalName}
                </a>
              </button>
            ))}
            <button className="btn btn-primary" onClick={() => onCancel()}>
              Back
            </button>
          </div>
        </dd>
      </dl>
    </React.Fragment>
  );
};
