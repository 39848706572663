import { API, ServerError } from "../network/API";
import { LOGOUT_ROUTE } from "../pages/Logout";

function actAndDelagate<T>(err: ServerError<T>) {
  if (err.status === 401) {
    const params = new URLSearchParams();
    const currentUrl = new URL(window.location.href);
    const url = `${currentUrl.pathname}${currentUrl.search}`;
    params.set("expired", url);
    window.location.href = `${LOGOUT_ROUTE}?${params}`;
  }

  return Promise.reject(err);
}

export interface PageResponse<T> {
  content: Array<T>;
  first: boolean;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export const data = {
  get: <T>(url: string, refreshCache?: boolean) => {
    return API.get<T>(url, refreshCache).catch(actAndDelagate);
  },

  post: <T>(url: string, body?: any) => {
    return API.post<T>(url, body).catch(actAndDelagate);
  },

  postForm: <T>(url: string, body?: any) => {
    return API.postForm<T>(url, body).catch(actAndDelagate);
  },

  delete: <T>(url: string, body?: any) => {
    return API.delete<T>(url, body).catch(actAndDelagate);
  },
};
