import { DateTime } from "luxon";
import { atom } from "recoil";
import { SessionResponse } from "../data/dataAuth";
import { Store, STORE_KEY } from "../Store";

const loadUser = () => {
  const user = Store.getValue<SessionResponse>(STORE_KEY.STORE_USER);
  if (user == null) {
    return null;
  }
  const expiry = DateTime.fromISO(user.expiry);
  //Don't load a user that is expired
  if (expiry.diffNow().milliseconds < 0) {
    Store.setValue(STORE_KEY.STORE_USER, null);
    return null;
  }
  return user;
};

export const userState = atom<SessionResponse | null>({
  key: "user",
  default: loadUser(),
});

export const sessionExpiredState = atom<boolean>({
  key: "sessionExpired",
  default: false,
});
