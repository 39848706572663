import { DateTime } from "luxon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RouteProps, useHistory, useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { STORE_KEY, Store } from "../Store";
import { SessionResponse } from "../data/dataAuth";
import { userState } from "../state/userState";
import { LoginEmail } from "./LoginEmail";
import { LoginOtp } from "./LoginOtp";
import "./LoginPage.scss";
import { Page } from "./Page";

export const LOGIN_ROUTE = "/";

export const LoginPage: React.FunctionComponent<RouteProps> = () => {
  const requestId = useRef<string>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [user, setUserState] = useRecoilState(userState);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!user) return;

    const isValid = DateTime.now() < DateTime.fromISO(user.expiry);
    if (isValid) {
      history.push("/dashboard");
    }
  }, [history, user]);

  const onNext = useCallback(
    (code: string | undefined) => {
      requestId.current = code;
      setTabIndex(tabIndex + 1);
    },
    [tabIndex]
  );

  const onLogin = useCallback(
    (data: SessionResponse) => {
      setUserState(data);
      Store.setValue(STORE_KEY.STORE_USER, data);
      const url = new URLSearchParams(location.search).get("expired");

      if (url) {
        history.push(url.toString());
      } else {
        history.push("/dashboard");
      }
    },
    [history, setUserState, location]
  );

  const onReset = useCallback(() => {
    requestId.current = undefined;
    setTabIndex(0);
  }, []);

  return (
    <Page stripped>
      <div className="card">
        <div className="card-body">
          <header>
            <h2>Sign in</h2>
          </header>
          {requestId.current ? (
            <LoginOtp
              requestId={requestId.current}
              onLogin={onLogin}
              onReset={onReset}
            />
          ) : (
            <LoginEmail onNext={onNext} />
          )}
        </div>
      </div>
    </Page>
  );
};
